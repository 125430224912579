<template>
  <div class="common-layout">
    <el-container>
      <el-aside style="background-color: #545C64;height: 100vh;" width="10vw">
        <el-row class="tac">
          <el-col :span="12">
            <el-menu
                active-text-color="#ffd04b"
                background-color="#545c64"
                style="width: 200%;"
                default-active="1"
                text-color="#fff"
            >
              <el-menu-item style="margin-left: 10%;" index="1" @click="toPage1">
                <img src="../../public/slices/领导关怀.png" alt="无">
                <p>领导关怀</p>
              </el-menu-item>
              <el-menu-item style="margin-left: 10%;" index="2" @click="toHistory">
                <img src="../../public/slices/领导关怀(1).png" alt="无">
                <p>历史沿革</p>
              </el-menu-item>
              <el-menu-item style="margin-left: 10%;" index="3" @click="toPreviousTeams">
                <img src="../../public/slices/领导关怀(2).png" alt="无">
                <p>历届班子</p>
              </el-menu-item>
              <el-menu-item style="margin-left: 10%;" index="4" @click="toEvents">
                <img src="../../public/slices/领导关怀(3).png" alt="无">
                <p>太原大事记</p>
              </el-menu-item>
              <el-menu-item style="margin-left: 10%;" index="5" @click="toPersonStyle">
                <img src="../../public/slices/领导关怀(4).png" alt="无">
                <p>台站风貌</p>
              </el-menu-item>
              <el-menu-item style="margin-left: 10%;" index="6" @click="toStaff">
                <img src="../../public/slices/领导关怀(5).png" alt="无">
                <p>职工风采</p>
              </el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </el-aside>
      <el-container>
        <el-header style="background-color: white;">
          <div class="pic"></div>
          <el-page-header @back="goBack" title="返回">
            <template #content>
              <span class="headerTitle">山西局史馆后台管理系统 </span>
              <span
                  class="text-sm mr-2"
                  style="color: var(--el-text-color-regular)"
              >
                {{ littleName }}
              </span>
            </template>
          </el-page-header>
        </el-header>
          <el-main style="height: 90vh;background-color: #EEF3F1;">
            <el-scrollbar>
              <router-view></router-view>
            </el-scrollbar>
          </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script setup>
import router from "@/router"
import {ref} from "vue"

const littleName = ref("领导关怀")

function toPage1(){
  router.push("/")
  littleName.value = "领导关怀"
}
function toHistory(){
  router.push("/history")
  littleName.value = "历史沿革"
}
function toPreviousTeams(){
  router.push("/previousTeams")
  littleName.value = "历届班子"
}
function toEvents(){
  router.push("/events")
  littleName.value = "太原大事记"
}
function toPersonStyle(){
  router.push("/personStyle")
  littleName.value = "台风台貌"
}
function toStaff(){
  router.push("/staff")
  littleName.value = "职工风采"
}

function goBack(){
  router.push("/")
  littleName.value = "领导关怀"
}
</script>

<style scoped>
@font-face {
  font-family: "myFont";
  src: url("../../public/font/black.otf");
}
.headerTitle{
  font-family: myFont;
  font-size: 1.5vw;
  line-height: 5vh;
  letter-spacing: 2px;
}
.pic{
  position: absolute;
  right: 2vw;
  background-image: url("../../public/slices/中国气象局_logo_百度搜索 1@2x.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 6vh;
  height: 6vh;
}
p{
  margin-left: 10px;
  font-weight: bolder;
  letter-spacing: 2px;
}
</style>
