<template>
  <el-upload
      ref="upload"
      class="upload-demo"
      :http-request="uploadRequest"
      :on-preview="handlePreview"
      :before-upload="beforeUpload"
      :on-remove="handleRemove"
      list-type="picture"
  >
    <el-button type="primary" :loading="loading">
      上传<el-icon class="el-icon--right" @click="triggerUpload"><Upload /></el-icon>
    </el-button>
  </el-upload>
  <div style="height: 2vh;"></div>
  <el-table table-layout="fixed" :data="tableData" style="width: 100%">
    <el-table-column prop="date" label="上传日期"></el-table-column>
    <el-table-column prop="name" label="图片名称"></el-table-column>
    <el-table-column label="图片">
      <template #default="scope">
        <el-image
            :hide-on-click-modal="true"
            :src="scope.row.picture[0]"
            style="width: 50px; height: 50px; object-fit: cover;"
            :preview-src-list="scope.row.picture"
            :preview-teleported="true"
        />
      </template>
    </el-table-column>
    <el-table-column label="操作">
      <template #default="scope">
        <el-button
            size="small"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
        >
          删除
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script setup>
import {onMounted, ref} from "vue"
import { Upload } from "@element-plus/icons-vue"
import {ElMessage} from "element-plus"
import axios from "axios";

const loading = ref(false)

const tableData = ref([])

const upload = ref(null)

const triggerUpload = () => {
  upload.value.submit()
}

const handleRemove = (file, uploadFiles) => {
  console.log(file, uploadFiles)
  console.log("handleRemove")
}

const handlePreview = (uploadFile) => {
  console.log(uploadFile)
  console.log("handlePreview")
}

const beforeUpload = (file) => {
  const isImage = file.type.startsWith("image/")

  if (!isImage) {
    ElMessage.error("上传文件必须是图片格式!")
  }
  return isImage
}

const uploadRequest = (file) => {
  loading.value = true

  const formData = new FormData()
  formData.append("file", file.file)

  // 设置其他相关字段，例如目录信息
  formData.append("directory", "history")

  // Perform the actual upload request using fetch, axios, etc.
  fetch("https://sxj.api.zsvisual.cn/upload/", {
    method: "POST",
    body: formData
  })
      .then(response => response.json())
      .then(() => {
        ElMessage({
          message:"上传成功",
          type:"success"
        })
        getData()
        loading.value = false
      })
      .catch(error => {
        console.log(error)
        ElMessage.error("上传失败")
        loading.value = false
      })
}

async function handleDelete(index, row) {
  const imageUrl = row.picture[0]
  try {
    await axios.delete('https://sxj.api.zsvisual.cn/delete-image', {
      data: {imageUrl}
    })
    ElMessage({
      message:"删除成功",
      type:"success"
    })
    await getData()
  } catch (error) {
    console.error('Error deleting image:', error);
  }
}

onMounted(getData)

async function getData() {
  try {
    const response = await axios.post('https://sxj.api.zsvisual.cn/fetch-images', {
      directory: "history"
    })
    tableData.value = []
    response.data.images.forEach(item => {
      const parts = item.split("/")
      const filename = parts[parts.length - 1]
      const [date, name] = filename.split("_")
      tableData.value.push({
        date: date,
        name: name,
        picture: [item]
      })
    })
  } catch (error) {
    console.error('Error fetching images:', error)
  }
}

</script>

<style scoped>
</style>
