import { createRouter, createWebHistory } from "vue-router"
import LeadershipCare from "../components/LeadershipCare"
import Events from "../components/Events"
import History from "../components/History"
import PersonStyle from "../components/PersonStyle"
import PreviousTeams from "../components/PreviousTeams"
import Staff from "../components/Staff"

const routes = [
    { path: "/", name: "LeadershipCare", component: LeadershipCare },
    { path: "/events", name: "Events", component: Events },
    { path: "/history", name: "History", component: History },
    { path: "/personStyle", name: "PersonStyle", component: PersonStyle },
    { path: "/previousTeams", name: "PreviousTeams", component: PreviousTeams },
    { path: "/staff", name: "Staff", component: Staff }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
