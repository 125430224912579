<template>
  <div>
    <div ref="toolbarContainer"></div>
    <div ref="editorContainer"></div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { createEditor, createToolbar } from '@wangeditor/editor'
import '@wangeditor/editor/dist/css/style.css' // 引入样式

export default {
  name: 'EditorComponent',
  props: {
    modelValue: String // 使用 v-model 进行双向绑定
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const toolbarContainer = ref(null)
    const editorContainer = ref(null)
    let editor = null
    let toolbar = null

    onMounted(() => {
      editor = createEditor({
        selector: editorContainer.value,
        config: {
          placeholder: 'Type here...',
          onChange: (editor) => {
            const html = editor.getHtml()
            emit('update:modelValue', html) // 实时更新父组件的值
          }
        }
      })

      // 立即触发一次更新，以确保父组件接收到空值
      emit('update:modelValue', '')

      toolbar = createToolbar({
        editor,
        selector: toolbarContainer.value,
        config: {
          // 自定义工具栏，排除图片和视频相关按钮
          excludeKeys: ['group-image', 'group-video']
        }
      })
    })

    onBeforeUnmount(() => {
      editor.destroy()
      toolbar.destroy()
    })

    return {
      toolbarContainer,
      editorContainer
    }
  }
}
</script>

<style scoped>
/* 添加一些样式以确保编辑器显示正常 */
div {
  margin: 0 !important;
}
</style>
