<template>

  <el-select v-model="chapterVal" size="large" @change="changeChapterValue" placeholder="请选择章节" style="width: 240px; margin-right: 2vw;">
    <el-option
        v-for="item in dataJson"
        :key="item['chapter']"
        :label="item['name']"
        :value="item['chapter']"
    />
  </el-select>
  <el-button type="primary" @click="addChapter">添加章节</el-button>

  <el-button type="danger" @click="delChapter">删除本章节</el-button>

  <div style="margin-bottom: 2vh;"></div>

  <el-descriptions
      class="margin-top"
      title="章节信息"
      :column="3"
      border
  >
    <template #extra>
      <el-button type="primary" @click="editChapterMessage">编辑</el-button>
    </template>
    <el-descriptions-item>
      <template #label>
        <div class="cell-item">
          章节
        </div>
      </template>
      {{dataJson[INdexValue]['name']}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template #label>
        <div class="cell-item">
          中文标题
        </div>
      </template>
      {{dataJson[INdexValue]['ChineseName']}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template #label>
        <div class="cell-item">
          英文标题
        </div>
      </template>
      {{dataJson[INdexValue]['EnglishName']}}
    </el-descriptions-item>
  </el-descriptions>

  <div style="margin-bottom: 3vh;"></div>

  <el-button type="primary" @click="addPage" style="margin-bottom: 1vh;">添加页面</el-button>

  <el-table table-layout="fixed" :data="tableData" style="width: 100%">
    <el-table-column prop="date" label="上传日期"></el-table-column>
    <el-table-column prop="name" label="图片名称"></el-table-column>
    <el-table-column prop="text" label="文本"></el-table-column>
    <el-table-column label="图片">
      <template #default="scope">
        <el-image
            :hide-on-click-modal="true"
            :src="scope.row.picture[0]"
            style="width: 50px; height: 50px; object-fit: cover;"
            :preview-src-list="scope.row.picture"
            :preview-teleported="true"
        />
      </template>
    </el-table-column>
    <el-table-column label="操作">
      <template #default="scope">
<!--        <el-button-->
<!--            size="small"-->
<!--            @click="handleEdit(scope.$index, scope.row)"-->
<!--        >-->
<!--          编辑-->
<!--        </el-button>-->

        <el-button
            size="small"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
        >
          删除
        </el-button>
      </template>
    </el-table-column>
  </el-table>

  <el-dialog v-model="fuwenbenVisible" v-if="fuwenbenVisible" title="添加页面" width="1000">
    <el-form :model="pageFrom" label-width="auto" style="max-width: 600px">
      <el-form-item required label="图片放置位置">
        <el-select v-model="pageFrom.picture" size="large" placeholder="请选择对齐方式" style="width: 240px; margin-right: 2vw;" @change="picAlignmentValChange">
          <el-option
              v-for="item in picAlignment"
              :key="item.value"
              :label="item.name"
              :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="上传图片">
        <el-upload
            ref="upload"
            class="upload-demo"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-change="pictureChange"
            :http-request="uploadRequest"
            :auto-upload="false"
            list-type="picture"
        >
          <template #trigger>
            <el-button :disabled="isHasPic" type="primary">选择照片</el-button>
          </template>
        </el-upload>
      </el-form-item>
      <el-form-item label="文本">
        <div style="height: 40vh;">
          <basicEditor v-model="htmlContent" />
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="submitPage">
          确定
        </el-button>
      </div>
    </template>
  </el-dialog>

  <el-dialog v-model="addChapterVisible" title="添加章节" width="500">
    <el-form :model="chapterForm">
      <el-form-item>
        <span>章节中文名称</span>
        <el-input v-model="chapterForm.chineseName" autocomplete="off" />
      </el-form-item>
      <el-form-item>
        <span>章节英文名称</span>
        <el-input v-model="chapterForm.EnglishName" autocomplete="off" />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="cancelSubmitChapter">取消</el-button>
        <el-button type="primary" @click="submitChapter">
          确定
        </el-button>
      </div>
    </template>
  </el-dialog>

  <el-dialog v-model="mesageVisible" title="修改章节" width="500">
    <el-form :model="chapterForm">
      <el-form-item>
        <span>章节中文名称</span>
        <el-input v-model="chapterForm.chineseName" autocomplete="off" />
      </el-form-item>
      <el-form-item>
        <span>章节英文名称</span>
        <el-input v-model="chapterForm.EnglishName" autocomplete="off" />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="submitEditChapter">
          确定
        </el-button>
      </div>
    </template>
  </el-dialog>

</template>

<script setup>
import {onMounted, ref} from "vue"
import {ElMessage, ElMessageBox} from "element-plus"
import axios from "axios"
import basicEditor from "@/components/basicEditor"

const INdexValue = ref(0)//全局变量，判断第几页

/**
 * 修改章节信息的变量
 *
 */
const chapterVal=ref("")
const mesageVisible=ref(false)

/**
 * 添加章节的变量
 *
 */
const addChapterVisible = ref(false)
const chapterForm = ref({
  EnglishName: '',
  chineseName: ''
})

/**
 * 添加页面的变量
 *
 */
const picAlignment=ref([
  {
    name:"左边",
    value:"left"
  },
  {
    name:"右边",
    value: "right"
  },
  {
    name:"无图片",
    value: "none"
  }
])
const isHasPic = ref(false)
const fuwenbenVisible = ref(false)
const pageFrom=ref({
  text:'',
  picture:'',
  picUrl:''
})
const htmlContent = ref('')
const pictureName = ref('')


const dataJson = ref([
      {
        "chapter": 0,
        "name": "",
        "ChineseName": "",
        "EnglishName": "",
        "pages": []
      }
    ])//服务器拿下来的json文件

const loading = ref(false)//上传途中不能点击按钮

const tableData = ref([])

const upload = ref(null)//upload的实例

/**
 * 改变查看章节
 */
function changeChapterValue(value){
  dataJson.value.forEach(eachChapter=>{
    if (eachChapter.chapter === value){
      INdexValue.value = value-1
      tableData.value = []
      eachChapter["pages"].forEach(item => {
        const parts = item["picUrl"].split("/")
        const filename = parts[parts.length - 1]
        const [date, name] = filename.split("_")
        tableData.value.push({
          date: date,
          name: name,
          text:item["text"],
          picture: [item["picUrl"]]
        })
      })

    }
  })
}

/**
 * 添加章节
 */
function addChapter(){
  addChapterVisible.value=!addChapterVisible.value
}
function delChapter(){
  dataJson.value.splice(INdexValue.value, 1)
  INdexValue.value=INdexValue.value-1
  chapterVal.value=dataJson.value[INdexValue.value]['name']
  uploadJson()
}
function cancelSubmitChapter(){
  addChapterVisible.value=!addChapterVisible.value
  chapterForm.value.chineseName=""
  chapterForm.value.EnglishName=""
}
function submitChapter(){
  console.log(chapterForm.value)
  //代码
  dataJson.value.push({
    "chapter": dataJson.value.length+1,
    "name": `第${dataJson.value.length+1}章`,
    "ChineseName":  chapterForm.value.chineseName,
    "EnglishName": chapterForm.value.EnglishName,
    "pages": []
  })
  uploadJson()
  addChapterVisible.value=!addChapterVisible.value
  chapterForm.value.chineseName=""
  chapterForm.value.EnglishName=""
}

/**
 * 添加页面
 */
function addPage(){
  htmlContent.value=""
  fuwenbenVisible.value=!fuwenbenVisible.value
}
function pictureChange(file){
  pictureName.value=file.name
  console.log(pictureName.value)
}
function picAlignmentValChange(value){
  if (value === "none"){
    isHasPic.value=true
  }else {
    isHasPic.value=false
  }
}
async function submitPage() {
  if (isHasPic.value){
    fuwenbenVisible.value = !fuwenbenVisible.value
    pageFrom.value.text = htmlContent.value
    dataJson.value[INdexValue.value]['pages'].push(pageFrom.value)
    await uploadJson()
    pageFrom.value.picUrl = ''
    pageFrom.value.picture = ''

  }else {
    if (pageFrom.value.picture === "") {
      await ElMessageBox.alert('请选择图片放置位置', '错误提示', {
        confirmButtonText: '确定'
      })
    } else {
      const date = new Date()
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0') // 月份从0开始
      const day = String(date.getDate()).padStart(2, '0')

      fuwenbenVisible.value = !fuwenbenVisible.value
      pageFrom.value.text = htmlContent.value
      pageFrom.value.picUrl = `https://sxj.api.zsvisual.cn/uploads/events/${year}-${month}-${day}_${pictureName.value}`
      dataJson.value[INdexValue.value]['pages'].push(pageFrom.value)
      if (pageFrom.value.picture !== "none") {
        await triggerUpload()
      }
      await uploadJson()
      pageFrom.value.picUrl = ''
      pageFrom.value.picture = ''
    }
  }
}

/**
 * 修改章节信息
 */
function editChapterMessage(){
  mesageVisible.value=!mesageVisible.value

}
function submitEditChapter(){
  dataJson.value[INdexValue.value].ChineseName = chapterForm.value.chineseName
  dataJson.value[INdexValue.value].EnglishName = chapterForm.value.EnglishName
  uploadJson()
  chapterForm.value.chineseName=""
  chapterForm.value.EnglishName=""
  mesageVisible.value=!mesageVisible.value
}

/**
 * 上传的那些方法
 */
const triggerUpload = () => {
  upload.value.submit()
}
const handleRemove = (file, uploadFiles) => {
  console.log(file, uploadFiles)
  console.log("handleRemove")
}
const handlePreview = (uploadFile) => {
  console.log(uploadFile)
  console.log("handlePreview")
}
const uploadRequest = (file) => {
  loading.value = true
  const formData = new FormData()
  formData.append("file", file.file)

  // 设置其他相关字段，例如目录信息
  formData.append("directory", "events")

  // Perform the actual upload request using fetch, axios, etc.
  fetch("https://sxj.api.zsvisual.cn/upload/", {
    method: "POST",
    body: formData
  })
      .then(response => response.json())
      .then(() => {
        ElMessage({
          message:"上传成功",
          type:"success"
        })
        loading.value = false
      })
      .catch(error => {
        console.log(error)
        ElMessage.error("上传失败")
        loading.value = false
      })
}

async function handleDelete(index, row) {
  const imageUrl = row.picture[0]
  dataJson.value[INdexValue.value]['pages'].splice(index, 1)
    await axios.delete('https://sxj.api.zsvisual.cn/delete-image', {
      data: {imageUrl}
    })
    ElMessage({
      message:"删除成功",
      type:"success"
    })
  uploadJson()

  // const imageUrl = row.picture[0]
  // try {
  //   await axios.delete('https://sxj.api.zsvisual.cn/delete-image', {
  //     data: {imageUrl}
  //   })
  //   ElMessage({
  //     message:"删除成功",
  //     type:"success"
  //   })
  //   await getData()
  // } catch (error) {
  //   console.error('Error deleting image:', error);
  // }
}

// function handleEdit(index,row){
//   console.log(dataJson.value[INdexValue.value]['pages'][index])
//   pageFrom.value.text = dataJson.value[INdexValue.value]['pages'][index].text
//   fuwenbenVisible.value = !fuwenbenVisible.value
// }
/**
 * 获取图片的方法
 */
async function getData() {
  try {
    const response = await axios.get('https://sxj.api.zsvisual.cn/get-data/')
    dataJson.value = response.data
    tableData.value = []
    dataJson.value[INdexValue.value]["pages"].forEach(item => {
      const parts = item["picUrl"].split("/")
      console.log(item["picUrl"])
      const filename = parts[parts.length - 1]
      const [date, name] = filename.split("_")
      tableData.value.push({
        date: date,
        name: name,
        text:item["text"],
        picture: [item["picUrl"]]
      })
    })

    // response.data.images.forEach(item => {
    //   const parts = item.split("/")
    //   const filename = parts[parts.length - 1]
    //   const [date, name] = filename.split("_")
    //   console.log(name)
    //   tableData.value.push({
    //     date: date,
    //     name: name,
    //     picture: [item]
    //   })
    // })
  } catch (error) {
    console.error('Error fetching images:', error)
  }
}

/**
 * 每次变更都把json传上去
 */
async function uploadJson() {
  await fetch("https://sxj.api.zsvisual.cn/save-data/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(dataJson.value)
  })
      .then(async () => {
        await getData()
      })

}

onMounted(async () => {
  await getData()
  chapterVal.value = dataJson.value[INdexValue.value]['name']
})

</script>

<style scoped>
</style>
