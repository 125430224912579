<template>
  <div id="app">
    <div v-if="isLoad" class="outBox">
      <div class="ring">
        <i style="--clr:#00ff0a;"></i>
        <i style="--clr:#ff0057;"></i>
        <i style="--clr:#fffd44;"></i>
        <div class="login">
         <div>
           <el-link v-if="false" :underline="false" :class="{'linkclick':loadMethod}" @click="loadMethod=true">账号密码登录</el-link>
           <span v-if="false"> | </span>
           <h1 :underline="false" :class="{'linkclick':!loadMethod}" @click="loadMethod=false">手机号登录</h1>
         </div>
          <div v-if="loadMethod" class="inputBx">
            <input type="text" v-model="userName" placeholder="用户名">
          </div>
          <div v-if="!loadMethod" class="inputBx">
            <input type="text" v-model="phone" placeholder="手机号">
          </div>

          <div v-if="loadMethod" class="inputBx">
            <input type="password" v-model="passWord" placeholder="密码">
          </div>
          <div v-if="!loadMethod" class="inputBx" style="display: flex;">
            <input class="yanzhenginput" type="password" v-model="verificationCode" placeholder="验证码">
            <el-button style="height: 100%;" round @click="sendVerificationCode" :disabled="countdown > 0">
              {{ countdown > 0 ? `${countdown}s后重发` : '获取验证码' }}
            </el-button>
          </div>

          <div class="inputBx">
            <input type="submit" value="登     录" @click="submit">
          </div>
        </div>
      </div>
    </div>
    <home v-else></home>
  </div>
</template>

<script setup>
import home from "./components/home"
import {onMounted, ref} from "vue"
import axios from "axios"
import Cookies from 'js-cookie'

const countdown=ref(0)

const loadMethod=ref(false)

const isLoad = ref(true)
const userName = ref("")
const passWord = ref("")

const phone=ref("")
const verificationCode=ref("")

const sendCode=ref("1")

function submit() {
  if (userName.value === "123" && passWord.value === "123"){
    axios.post("https://sxj.api.zsvisual.cn/login",{name:"admin"})
        .then(res=>{
          alert("登录成功！")
          Cookies.set('login', res.data.cookie, { expires: 7, path: '' })
          isLoad.value=false
        })
  }else if (verificationCode.value===sendCode.value.toString()){
      axios.post("https://sxj.api.zsvisual.cn/login",{phoneNumber:phone.value})
          .then(res=>{
            alert("登录成功！")
            Cookies.set('login', res.data.cookie, { expires: 7, path: '' })
            isLoad.value=false
          })
  } else {
    if (verificationCode.value!==sendCode.value.toString()){
      alert("验证码错误！")
      return
    }
    alert("账号密码错误！")
  }
}

function sendVerificationCode(){
  if (!/^1[3-9]\d{9}$/.test(phone.value)) {
    alert('请输入正确的手机号码！')
    return
  }
  if (phone.value==='15119550508'||phone.value==='15332308990'||phone.value==='18621110304'||phone.value==='18636165196'){
    countdown.value = 60
    const interval = setInterval(() => {
      countdown.value--
      if (countdown.value === 0) clearInterval(interval)
    }, 1000)
    axios.post("https://sxj.api.zsvisual.cn/sendVerificationCode",{phoneNumber:phone.value})
        .then(res=>{
          sendCode.value=res.data.code
        })
  }else {
    alert('请输入有效的手机号码!')
  }
}

onMounted(()=>{
  if (Cookies.get('login')){
    isLoad.value=false
  }
})
</script>

<style scoped>
#app{
  background-color: #EEF3F1;
}
/* vietnamese */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/public/font/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkKEo58m-wi40.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/public/font/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkKEo58i-wi40.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/public/font/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkKEo58a-wg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.outBox{
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ring {
  position: relative;
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ring i {
  position: absolute;
  inset: 0;
  border: 2px solid #fff;
  transition: 0.5s;
}
.ring i:nth-child(1) {
  border-radius: 38% 62% 63% 37% / 41% 44% 56% 59%;
  animation: animate 6s linear infinite;
}
.ring i:nth-child(2) {
  border-radius: 41% 44% 56% 59%/38% 62% 63% 37%;
  animation: animate 4s linear infinite;
}
.ring i:nth-child(3) {
  border-radius: 41% 44% 56% 59%/38% 62% 63% 37%;
  animation: animate2 10s linear infinite;
}
.ring:hover i {
  border: 6px solid var(--clr);
  filter: drop-shadow(0 0 20px var(--clr));
}
@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.login {
  position: absolute;
  width: 15vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.login span {
  font-size: 2vh;
  color: #fff;
}

.linkclick{
  color: white;
  font-size: 2vh;
}

.login .inputBx {
  position: relative;
  width: 100%;
}
.login .inputBx input{
  position: relative;
  width: 90%;
  padding: 5%;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 40px;
  font-size: 1.2em;
  color: #fff;
  box-shadow: none;
  outline: none;
}
.login .inputBx input[type="submit"] {
  width: 100%;
  background: #0078ff;
  background: linear-gradient(45deg, #ff357a, #fff172);
  border: none;
  cursor: pointer;
}
 .login .inputBx input::placeholder {
  color: rgba(255, 255, 255, 0.75);
}
</style>
